/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

//import { AnimatePresence } from "framer-motion"

require('semantic-ui-less/semantic.less');

// handle offset for Gatsby Link anchor hash link
let offsetY = 0
let additionalOffsetY = 110
const getTargetOffset = hash => {
  const id = window.decodeURI(hash.replace(`#`, ``))
  if (id !== ``) {
    const element = document.getElementById(id)
    if (element) {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      let clientTop =
        document.documentElement.clientTop || document.body.clientTop || 0
      let computedStyles = window.getComputedStyle(element)
      let scrollMarginTop =
        computedStyles.getPropertyValue(`scroll-margin-top`) ||
        computedStyles.getPropertyValue(`scroll-snap-margin-top`) ||
        `0px`

      return (
        element.getBoundingClientRect().top +
        scrollTop -
        parseInt(scrollMarginTop, 10) -
        clientTop -
        offsetY
      )
    }
  }
  return null
}

/* export const wrapPageElement = ({ element }) => (
  <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
) */

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
   //console.log("onRouteUpdate - location", location);
  // console.log("new pathname", location.pathname);
  // console.log("old pathname", prevLocation ? prevLocation.pathname : null);
  // run OneTrust SDK init when route changes
  if (window.OneTrust && window.OneTrust.initializeCookiePolicyHtml) {
    //console.log('onRouteUpdate - OneTrust - initializeCookiePolicyHtml');
    window.OneTrust.initializeCookiePolicyHtml();
  }
}
/* exports.onRouteUpdate = ({ location, prevLocation }) => {
  console.log("onRouteUpdate - location", location)
  console.log("new pathname", location.pathname)
  console.log("old pathname", prevLocation ? prevLocation.pathname : null)
  // if (window.ApesterSDK) {
  //   window.ApesterSDK.Init()
  // }
} */

// handle offset for Gatsby Link anchor hash link 
export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  //console.log("shouldUpdateScroll - location", location);
  const offset = getTargetOffset(location.hash);
  //console.log('shouldUpdateScroll - offset', offset);
  const additionalOffset = offset-additionalOffsetY
  //console.log('shouldUpdateScroll - additionalOffset', additionalOffset);
  setTimeout(() => {
    window.scrollTo({top: additionalOffset, left: 0, behavior: 'smooth'});
  }, 0);
  return offset !== null ? [0, additionalOffset] : true
  //return offset !== null ? [0, offset] : true
}
export const onInitialClientRender = (_, pluginOptions) => {
  requestAnimationFrame(() => {
    const offset = getTargetOffset(window.location.hash)
    //console.log('onInitialClientRender - offset', offset);
    const additionalOffset = offset-additionalOffsetY
    //console.log('onInitialClientRender - additionalOffset', additionalOffset);
    if (offset !== null) {
      window.scrollTo(0, additionalOffset)
    }
  })
}

/* // must be on Gatsby v2.28 or later -- previous versions had a bug with getSavedScrollPosition that was resolved with v2.28
// wait until page exit animation has completed before updating scroll position
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  // transition duration from `layout.js` * 1000 to get time in ms
  const TRANSITION_DELAY = 0.3 * 1000 * 2

  // if it's a "normal" route
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
  }

  // if we used the browser's forwards or back button
  else {
    // breaking change in shouldUpdateScroll browser API hook:
    // https://github.com/gatsbyjs/gatsby/issues/23842
    // looks like they fixed it in Gatsby v. 2.28.1
    // https://github.com/gatsbyjs/gatsby/pull/27384

    const savedPosition = getSavedScrollPosition(location) || [0, 0]

    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  }

  return false
} */

/* exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  console.log("shouldUpdateScroll - location", location);
  //let offset = location.hash ? getTargetOffset(window.location.hash) : 0;
  //console.log("shouldUpdateScroll - offset", offset);

  const currentPosition = getSavedScrollPosition(location)
  const queriedPosition = getSavedScrollPosition({ hash: location.hash })
  console.log('shouldUpdateScroll - currentPosition', currentPosition);
  console.log('shouldUpdateScroll - queriedPosition', queriedPosition);

  // window.scrollTo(...(currentPosition || [0, 0]))

  // return false
} */

/* exports.onInitialClientRender = (_, pluginOptions) => { 
  console.log('onInitialClientRender - pluginOptions', pluginOptions);
  // if (pluginOptions.offsetY) { 
  //   offsetY = pluginOptions.offsetY 
  // } 
 
  // requestAnimationFrame(() => {
  //   const offset = getTargetOffset(window.location.hash);
  //   console.log('requestAnimationFrame - offset', offset);
  //   if (offset !== null) { 
  //     window.scrollTo(0, offset) 
  //   } 
  // }) 
} */
